import Navbar from '../nav'
import { Container, Col, Row, Button, Form, Card, Alert, InputGroup, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import { useState } from 'react'


// https://htmlstream.com/preview/space-v2.3.1/index.html

function Main() {
    const [messagesSent, setMessagesSent] = useState(0);
    const env = process.env.NODE_ENV;
    console.log(env)

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid, isSubmitting },
    } = useForm({
        defaultValues: {
            totalMessages: 10,
            phoneNumber: ''
        },
        mode: "onChange"
    });

    function startCounter(execSeconds) {
        console.log("Start timer for", execSeconds, " seconds")
        return new Promise((resolve) => {
            let totalSeconds = 0;
            const timer = setInterval(() => {
                totalSeconds++;
                setMessagesSent(totalSeconds)
                if (totalSeconds > execSeconds) {
                    clearInterval(timer);
                    resolve(totalSeconds);
                }
            }, 700);
            return () => clearInterval(timer);
        });
    }


    async function bombExecution(data) {
        try {
            const baseUrl = process.env.NODE_ENV === "production" ? "https://api.smstornado.com" : "http://localhost:8000";
            const url = `${baseUrl}/bomb/${data.phoneNumber}?totalmessages=${data.totalMessages}`;
            await fetch(url);
            await startCounter(data.totalMessages);
        }
        catch (err) {
            console.log(err)
        } finally {
            setMessagesSent(0);
        }
    }

    return (
        <>
            <Alert className='border-0 rounded-0 text-center py-2 m-0 bg-dark text-white main-alert'>
                Our mobile app is launching soon!
            </Alert>
            <Navbar />
            <div className='bg-primary px-4'>
                <Container fluid>
                    <Row className='main-section align-items-center'>
                        <Col md={6} className='left-side'>
                            <h1 className='text-white'>
                                SMS Tornado is the Most Powerful Sms Bomber Over The Internet
                            </h1>
                            <p className='text-white'>
                                This is the best tool for performing pranks on someone
                            </p>
                            <div className="row">
                                <div className="col-xl-6">
                                    {/* Info Block */}
                                    <div className="feature-item d-flex">
                                        <div className="flex-shrink-0 text-center">
                                            <span className="svg-icon text-secondary">
                                                <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="40px" width="40px" xmlns="http://www.w3.org/2000/svg"><path d="M3 3H9V5H6.46173L11.3047 9.84298L9.8905 11.2572L5 6.3667V9H3V3Z" fill="currentColor"></path><path d="M3 21H9V19H6.3764L11.3046 14.0718L9.89038 12.6576L5 17.548V15H3V21Z" fill="currentColor"></path><path d="M15 21H21V15H19V17.5244L14.1332 12.6576L12.719 14.0718L17.6472 19H15V21Z" fill="currentColor"></path><path d="M21 3H15V5H17.5619L12.7189 9.84301L14.1331 11.2572L19 6.39032V9H21V3Z" fill="currentColor"></path></svg>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className="text-white mb-0">Upto 500 messages</h6>
                                            <p className="text-white-70">
                                                Easily send up to 500 messages at once with our SMS Bomber
                                            </p>
                                        </div>
                                    </div>
                                    {/* End Info Block */}
                                </div>
                                <div className="col-xl-6">
                                    <div className="d-flex feature-item">
                                        <div className="flex-shrink-0">
                                            <span className="svg-icon text-white">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="40px" width="40px" xmlns="http://www.w3.org/2000/svg"><path d="M695.4 164.1H470.8L281.2 491.5h157.4l-60.3 241 319.8-305.1h-211z"></path><path d="M848.1 359.3H627.8L825.9 109c4.1-5.3.4-13-6.3-13H436.1c-2.8 0-5.5 1.5-6.9 4L170.1 547.5c-3.1 5.3.7 12 6.9 12h174.4L262 917.1c-1.9 7.8 7.5 13.3 13.3 7.7L853.6 373c5.2-4.9 1.7-13.7-5.5-13.7zM378.3 732.5l60.3-241H281.2l189.6-327.4h224.6L487.1 427.4h211L378.3 732.5z"></path></svg>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h6 className="text-white mb-0">Lightning-Speed SMS Delivery</h6>
                                            <p className="text-white-70">
                                                With our SMS Bomber, enjoy lightning-fast message delivery
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Col>
                        <Col className='p-0' md={6}>
                            <Card>
                                <Card.Body className='p-5'>
                                    <Row className="align-items-sm-center mb-5 card-row">
                                        <Col sm={9}>
                                            <h5 className="card-title">
                                                Start Bombing Messages</h5>
                                            <p className="card-text text-muted">
                                                This tool is for fun purpose only, Please do not use to harm anyone
                                            </p>
                                        </Col>
                                        <Col sm={3}>
                                            <svg viewBox="0 0 1082 869" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M819.5 237L828.5 257L805.5 250.5C766.3 281.3 725.5 271.833 714 265C687.666 251.5 660 219.5 681.5 185.5C706.019 146.725 765.333 162.333 788 172C799.666 175.833 824.1 186.9 828.5 200.5C832.9 214.1 824.333 230.5 819.5 237Z" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M720 189H749.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M712.5 211H786.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M710 229.5L757.5 232.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M949 463C988.2 463.4 999 415.5 1029.5 417.5C1013.5 440.5 1022.83 466 1034 471C1029.2 513 1008.33 554.5 998.5 570C984.333 593.5 937.1 634.2 861.5 609C785.9 583.8 795.667 511.833 810 479C821.5 451 858.5 470.5 861.5 475C864.5 479.5 878 489 886 479C894 469 900 462.5 949 463Z" fill="#223B41" />
                                                <path d="M910.5 549C918.833 551.333 936.9 552 942.5 536" stroke="white" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M873 411C873 429.018 858.829 443.5 841.5 443.5C824.171 443.5 810 429.018 810 411C810 392.982 824.171 378.5 841.5 378.5C858.829 378.5 873 392.982 873 411Z" stroke="#223B41" strokeWidth={5} />
                                                <path d="M969 413C969 430.983 954.639 445.5 937 445.5C919.361 445.5 905 430.983 905 413C905 395.017 919.361 380.5 937 380.5C954.639 380.5 969 395.017 969 413Z" stroke="#223B41" strokeWidth={5} />
                                                <ellipse cx={848} cy="418.5" rx="6.5" ry="7.5" fill="#223B41" />
                                                <ellipse cx={931} cy="418.5" rx="6.5" ry="7.5" fill="#223B41" />
                                                <path d="M870.5 397C877.052 393.227 893.824 387.945 908.5 397" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M971.5 404.5L1047.5 402" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M1045.5 429C1047 433.333 1049.1 443.8 1045.5 451" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M1032.5 412.5C1033.5 410.5 1062.38 394.063 1069.55 426.895C1073.63 445.549 1065.76 477.663 1040 480.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M873.61 412C875.444 422.667 876.21 446.9 864.61 458.5C850.11 473 869.11 496 888.61 480" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M822.5 477C818.667 471 813.3 455.3 822.5 440.5C834 422 855.5 365 857 339" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M864.5 314C873 293 902.8 251.5 954 253.5C1005.2 255.5 1029.67 277.333 1035.5 288M1035.5 288C1040.17 305.167 1045.7 350.5 1030.5 394.5L1055 404.5C1060.83 379.5 1065.1 321.2 1035.5 288Z" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M1030 396C1002.73 324.938 907.805 311.354 864.5 314.446L803 315.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M808.5 415C783.667 419.167 729.1 416.4 727.5 370C725.5 312 822 299.5 858.5 338C895 376.5 1020 403.5 1036.5 399" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M782 519.5C782.167 529.667 784.6 552.3 793 561.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M850.5 396.105C852.333 391.272 858.9 381.905 870.5 383.105" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M926.5 396.417C930.667 393.084 941.5 388.417 951.5 396.417" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M1009 549L1019 583C1025.5 606 915.5 663.5 910.5 635L906 608.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M327.5 658.5C349.9 648.5 360.833 668.667 363.5 680C394.7 663.6 408.833 592.833 412 559.5C447.6 588.7 491.833 571.667 509.5 559.5C553.788 529 543 496.125 515.5 475.5C499.5 463.5 493.833 451.167 491 442C483.167 454.167 476.5 475.5 455.5 487.5C445.081 493.454 413 494.071 396 498C306 518.8 312.833 613.667 327.5 658.5Z" fill="#223B41" />
                                                <path d="M330 654.5C323.667 666.333 317.3 696.5 348.5 706.5C351.167 718.5 365.3 744.9 400.5 754.5C444.5 766.5 476 762.5 491.5 751C507 739.5 527.5 708.5 529.5 679" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M512 678.5L534.5 677C538.5 673.5 546.5 659.5 531 655.5C525.333 654.5 512.8 650.5 508 642.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M508 559C511.167 562.833 517 573 515 583" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M433 624C434.167 621.167 437.8 614.5 443 610.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <ellipse cx="471.5" cy={639} rx={4} ry={6} fill="#223B41" />
                                                <ellipse cx="528.5" cy={613} rx={4} ry={6} fill="#223B41" />
                                                <path d="M486 642.5C486 659.476 474.432 672.5 461 672.5C447.568 672.5 436 659.476 436 642.5C436 625.524 447.568 612.5 461 612.5C474.432 612.5 486 625.524 486 642.5Z" stroke="#223B41" strokeWidth={5} />
                                                <path d="M538 615C538 623.348 535.459 630.809 531.471 636.127C527.483 641.445 522.164 644.5 516.5 644.5C510.836 644.5 505.517 641.445 501.529 636.127C497.541 630.809 495 623.348 495 615C495 606.652 497.541 599.191 501.529 593.873C505.517 588.555 510.836 585.5 516.5 585.5C522.164 585.5 527.483 588.555 531.471 593.873C535.459 599.191 538 606.652 538 615Z" stroke="#223B41" strokeWidth={5} />
                                                <path d="M483 627L495 620.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M497 586C498.333 583.167 502.4 577.6 508 578" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M391.5 644L435.5 645.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M345 673.5C343.167 676.333 340.6 683 345 687" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M455 696.5C450.333 697.667 442 704 446 720" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M462.5 713C474.333 711.624 499.7 706.497 506.5 697" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M461.5 713.5C472.667 722.667 497.3 732.4 506.5 698" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M370.5 739C366.667 744.167 361.7 756.3 372.5 763.5C383.3 770.7 402 776.833 410 779C418.5 781.5 435.8 779.5 431 761.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M518.5 745C525.833 741 539.9 728.3 537.5 709.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M561 789L555 758.5L576 780C596 760.4 662.838 761.103 693.5 770.5C724.5 780 735 806.5 723 831.5C711 856.5 641 875.5 585.5 862C541.1 851.2 550.667 808.833 561 789Z" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M596 800.5L618.5 795.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M594.5 821.5L689.5 809.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M595.5 840.987L654.93 833.985" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M171.522 404V419.5C171.189 424.667 174.522 435.2 190.522 436C210.522 437 243.522 437.5 260.022 432C273.222 427.6 274.189 413.833 273.022 407.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M168.5 405C140.5 383.5 132.5 363 123.5 342.5C113.441 340.776 108.232 338.699 104.5 336.5C101.739 334.873 98 331.5 96.5 328.5C85.962 307.424 93 296.5 99.5 291C107.889 283.902 117.6 282.7 158 273.5C198.4 264.3 229.833 218.333 240.5 196.5C246.5 224.9 302.333 262 329.5 277C348.3 240.2 330.667 194 319.5 175.5C267.9 95.8999 184.667 108.667 149.5 125C122.5 79.9999 69.3333 100.167 57 109C31.3333 128.333 7.3 175.7 36.5 246.5C65.7 317.3 24.3333 345.333 0 350.5C29.2 408.9 123.167 411.833 168.5 405Z" fill="#223B41" />
                                                <path d="M335 362.5C333.5 368.667 328.5 381.6 320.5 384" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M114.5 301.5C112.5 306 110.4 316.2 118 321" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M328 276.5C325.5 312 339 380.5 267.5 407C242 415.5 189.1 417.6 161.5 398C151.333 391.333 129.5 373 123.5 345" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M263.5 289C263.5 298 267.432 308.267 274.5 317.5C280.241 325 274.167 332.667 267.5 337" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <ellipse cx="296.741" cy={285} rx="7.5" ry={9} fill="#223B41" />
                                                <ellipse cx="227.741" cy={292} rx="7.5" ry={9} fill="#223B41" />
                                                <path d="M288.5 267C290.5 262.833 296.5 255.1 304.5 257.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M211.5 266.5C215.833 263.833 227.1 259.9 237.5 265.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M381.004 112.804C374.004 123.804 359.504 145.804 357.504 145.804L361.504 97.8042C352.004 87.8042 339.904 59.8042 367.504 27.8042C395.104 -4.19583 451.337 1.47084 476.004 8.30417C496.337 15.9708 528.904 42.2042 496.504 85.8042C464.104 129.404 406.004 121.971 381.004 112.804Z" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M383.5 48L413 43" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M388 68.5L465.5 56" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M396.5 91L438.5 85.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                                <path d="M231.741 353.5C235.241 363 241.741 372 261.741 369.5" stroke="#223B41" strokeWidth={5} strokeLinecap="round" />
                                            </svg>
                                        </Col>
                                    </Row>

                                    <Form onSubmit={handleSubmit(bombExecution)}>
                                        <div className="mb-4">
                                            <Form.Label >
                                                Enter Phone Number
                                            </Form.Label>

                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>🇮🇳 +91</InputGroup.Text>
                                                <Form.Control
                                                    className='phoneNumInput'
                                                    id='phoneNumber'
                                                    min={0}
                                                    type="number"
                                                    inputMode='numeric' pattern="[0-9]*"
                                                    placeholder="Phone Number"
                                                    {...register('phoneNumber', {
                                                        required: "Phone number is required",
                                                        minLength: { value: 10, message: "Phone number is not valid" },
                                                        maxLength: { value: 10, message: "Phone number is not valid" },
                                                        validate: (value) => {
                                                            if (env === "production" && value === "8920821677") {
                                                                return "Being smart is good but being too much smart is injurious"
                                                            }
                                                            return true
                                                        }
                                                    })}
                                                    isInvalid={errors.phoneNumber?.message}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {
                                                        errors.phoneNumber?.message
                                                    }
                                                </Form.Control.Feedback>

                                            </InputGroup>
                                        </div>
                                        <div className="mb-4">
                                            <Form.Label >
                                                Total Messages to Send
                                            </Form.Label>

                                            <Form.Control
                                                id='totalMessages'
                                                min={0}
                                                type="number"
                                                inputMode='numeric' pattern="[0-9]*"
                                                placeholder="Total number"
                                                {...register('totalMessages', {
                                                    min: { value: 5, message: "Minimum messages should be 5" },
                                                    max: { value: 50, message: "You are not allowed to send more than 50 messages" }
                                                })}
                                                isInvalid={errors.totalMessages?.message}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    errors.totalMessages?.message
                                                }
                                            </Form.Control.Feedback>
                                        </div>
                                        <div className='mb-4'>
                                            <Button type="submit" variant="primary" className='w-100'
                                                disabled={!isDirty || !isValid || isSubmitting}
                                            >
                                                Start Bombing
                                            </Button>
                                        </div>

                                        {isSubmitting && (
                                            <div className='d-flex align-items-center'>
                                                <Spinner
                                                    size="sm"
                                                    animation="border" variant="primary"
                                                    className='me-2'
                                                />

                                                <p className='mb-0'>
                                                    Sending messages, {
                                                        messagesSent
                                                    } messages sent
                                                </p>
                                            </div>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Main
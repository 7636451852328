import { Navbar, Container, Nav, Button } from 'react-bootstrap'

function Navigation() {
    return (
        <Navbar bg="light" data-bs-theme="dark">
            <Container fluid>
                <Navbar.Brand href="#home">
                    <img src='logo.png' alt='sms-bomber-logo' />
                    SMS Tornado
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Button variant="primary" disabled>Email Bomber (launching soon)</Button>
                </Nav>
            </Container>
        </Navbar>
    )
}

export default Navigation